import {
  Backdrop,
  Card,
  CardContent,
  Typography,
  Box,
  Button,
  Avatar,
  Container,
  Modal,
  styled,
  Tooltip
} from '@mui/material';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import HomeIcon from '@mui/icons-material/Home';
import AddIcon from '@mui/icons-material/Add';
import { EventObject } from '@toast-ui/calendar';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import dayjs from 'dayjs';
import { formatDateCalendarPopUp } from '../../utils/general';
import { convertTimeFormat } from '../../utils/calendarHelper';
import AvatarGroup from '../AvatarGroup';

const BoxContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: 16
}));

const BoxSimple = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: 8
}));

interface Props {
  calendarEventsData: any;
  detailPopUpData: EventObject;
  isOpen: boolean;
  popUpPosition: { x: number; y: number };
  selectedView: string;
  setOpenDetailPopup: React.Dispatch<React.SetStateAction<boolean>>;
}

function CalendarDetailPopup({
  isOpen,
  detailPopUpData,
  calendarEventsData,
  popUpPosition,
  selectedView,
  setOpenDetailPopup
}: Props) {
  const { title, attendees, location, raw } = detailPopUpData;
  const [auxManualClose, setAuxManualClose] = useState<boolean>(true);
  const navigate = useNavigate();
  const startDate = new Date(detailPopUpData?.start?.d?.d || '');

  const formattedDate = dayjs(detailPopUpData?.start).format('M/D/YYYY');
  const DateONstarted = dayjs(detailPopUpData?.start).format('YYYY-MM-DD');

  let eventFounded = null;
  // eslint-disable-next-line no-restricted-syntax
  for (const key in calendarEventsData) {
    if (
      dayjs(calendarEventsData[key].start).format('YYYY-MM-DD') ===
        DateONstarted ||
      calendarEventsData[key].start === DateONstarted
    ) {
      eventFounded = calendarEventsData[key];
      break; // Terminar el bucle una vez que se encuentre el evento
    }
  }
  const utcUpadtedAt = eventFounded?.updatedAt;

  return (
    <Container
      sx={{
        alignItems: 'normal',
        margin: '40px'
        // padding: '40px'
      }}
    >
      <Modal
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
        onClose={() => {
          setOpenDetailPopup(false);
          setAuxManualClose(false);
        }}
        open={auxManualClose}
        sx={{
          alignItems: 'center'
        }}
      >
        <Card
          sx={{
            borderTop: `16px solid ${detailPopUpData.backgroundColor}`,
            display: isOpen ? 'block' : 'none',
            left: popUpPosition.x,
            position: 'sticky',
            top: popUpPosition.y,
            width: '363px'
          }}
        >
          <CardContent>
            <BoxContainer flexDirection="column">
              <BoxContainer flexDirection="row">
                <Typography
                  color="primary.500"
                  key="calendar-detail-popup-start-date"
                  variant="heading_300"
                >
                  {formatDateCalendarPopUp(startDate)}
                </Typography>
                <Typography
                  color="primary.400"
                  key="calendar-detail-popup-title"
                  variant="heading_300"
                >
                  {title}
                </Typography>
              </BoxContainer>
              <BoxSimple flexDirection="column">
                {Boolean(attendees.length) && (
                  <BoxSimple alignItems="center" color="surface.main">
                    <PersonOutlineIcon />
                    <Typography color="surface.main" variant="body_200">
                      {attendees.join(', ')}
                    </Typography>
                  </BoxSimple>
                )}
                {location && (
                  <Typography
                    alignItems="center"
                    color="surface.main"
                    display="flex"
                    gap={1}
                    variant="body_200"
                  >
                    <HomeIcon />
                    {location}
                  </Typography>
                )}

                {Boolean(raw?.services.length) && (
                  <Box flexDirection="column">
                    <Typography color="primary.main" variant="heading_300">
                      {selectedView === 'month' ? 'Services' : 'Tasks'}
                    </Typography>
                    {raw.services.map((service: any) => (
                      <Box color="surface.main" key={service.id}>
                        <Typography
                          fontSize={14}
                          key="calendar-detail-popup-service-time-name"
                          variant="secondary-bold"
                        >
                          {service.name}
                        </Typography>
                        {selectedView === 'month' && (
                          <Typography
                            fontSize="14px"
                            fontWeight={700}
                            key="calendar-detail-popup-starttime-endtime"
                          >
                            {convertTimeFormat(service?.startTime)} -{' '}
                            {convertTimeFormat(service?.endTime)}
                          </Typography>
                        )}
                        {selectedView === 'month' &&
                          service?.services.map((auxServices: any) => (
                            <Typography
                              fontSize="14px"
                              fontWeight={700}
                              key="calendar-detail-popup-service-name"
                            >
                              - {auxServices.name}
                            </Typography>
                          ))}
                      </Box>
                    ))}
                  </Box>
                )}
                {Boolean(raw?.members.length) && (
                  <BoxSimple flexDirection="column">
                    <Typography
                      color="primary.main"
                      fontWeight={700}
                      variant="button_200"
                    >
                      Team:
                    </Typography>
                    <BoxSimple alignItems="center">
                      <AvatarGroup max={10} users={raw.members} />
                    </BoxSimple>
                  </BoxSimple>
                )}
              </BoxSimple>
              <Button
                fullWidth
                onClick={() => {
                  navigate(
                    `/app/projects/detail/${detailPopUpData.raw.projectId}`,
                    {
                      state: {
                        dayChecked: formattedDate,
                        id: detailPopUpData.raw.projectId,
                        lastPage: '/app/calendar',
                        type: 'calendar',
                        utcUpadtedAt
                      }
                    }
                  );
                }}
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  gap: 1
                }}
                variant="primary"
              >
                <AddIcon />
                Details
              </Button>
            </BoxContainer>
          </CardContent>
        </Card>
      </Modal>
    </Container>
  );
}

export default CalendarDetailPopup;
