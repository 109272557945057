import React, { useState } from 'react';
import { Middleware, SWRConfig, SWRHook } from 'swr';
import { useNavigate } from 'react-router-dom';
import useAuthStore from './store/zustand/auth';
import './style/App.scss';
import AuthenticatedApp from './routes/AuthenticatedApp';
import UnauthenticatedApp from './routes/UnauthenticatedApp';
import appConfig from './config';
import { localCache } from './utils/cache';
import { Message } from './types';
import ToastNotification from './components/shared/ToastNotification';

const App: React.FC = function App() {
  const { isAuthenticated, logout } = useAuthStore((state) => state);
  const [message, setMessage] = useState<Message>();
  const navigate = useNavigate();

  const swrMiddleware: Middleware =
    (useSWRNext: SWRHook) => (key, fetcher, config) => {
      // action before runs a swr
      const swr = useSWRNext(key, fetcher, config);
      // action after runs a swr hook
      return swr;
    };

  const apiErrorHandling = (error: any) => {
    const { response } = error;
    console.log('==>> GLOBAL ERROR HANLDING', error, response?.status);
    if (response && response.status === 401) {
      const { data } = response;
      if (data?.requestChangePassword) {
        setTimeout(() => {
          navigate('/changePassword');
        }, 2000);
        return;
      }
      if (!data?.userBlock) {
        // TODO: We need to define a flow to show a user blocked
        // logout();
      }
      logout();
      setTimeout(() => {
        navigate('/login');
      }, 500);
    } else if (error && error.code && error.code === 'ERR_NETWORK') {
      // handling error for network
    }
  };

  return (
    <SWRConfig
      value={{
        onError: apiErrorHandling,
        provider: () => localCache,
        use: [swrMiddleware]
      }}
    >
      {!isAuthenticated ? <UnauthenticatedApp /> : <AuthenticatedApp />}
      {message && (
        <ToastNotification
          onClose={() => setMessage(undefined)}
          text={message.text}
          type={message.type}
        />
      )}
    </SWRConfig>
  );
};

export default App;
