/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
import React, { useEffect, useRef, useState } from 'react';
import { Box, Avatar, Tooltip, Typography } from '@mui/material';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';
import api from '../api';
import CustomTooltip from './shared/CustomTooltip';
import { theme } from '../theme/themeConfig';
import { resourseColors } from '../utils/general';

const SPACINGS = {
  large: '-2px',
  medium: '-6px',
  small: '-10px'
};

interface User {
  firstName?: string;
  id: string;
  lastName?: string;
  name?: string;
}

interface Props {
  max: number;
  sizeAvatar?: number;
  spacing?: 'medium' | 'small' | 'large';
  users: User[];
}

function ListExtraAvatars({ names }: { names: string[] }) {
  return (
    <Box display="flex" flexDirection="column" padding="0px 10px 10px 10px">
      {names.map((item: string, index: number) => (
        <Typography fontSize={14} variant="body1">
          {item}
        </Typography>
      ))}
    </Box>
  );
}

const AvatarGroup: React.FC<Props> = function AvatarGroup({
  max,
  users,
  spacing = 'medium',
  sizeAvatar = 40
}) {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const [visibleAvatars, setVisibleAvatars] = useState(max);

  const userPhotos = users.map((user) => {
    const { data: photoResponse } = useSWR(
      { id: user.id, url: 'photo/profile' },
      api.StorageApi.getProfilePhotoByID
    );
    const photo = photoResponse?.data ? photoResponse.data[1]?.fileUrl : null;
    return {
      photo,
      user
    };
  });

  const updateVisibleAvatars = () => {
    const containerWidth = containerRef.current?.offsetWidth || 0;
    const avatarSize = sizeAvatar;
    const maxAvatars = Math.floor(containerWidth / avatarSize);
    setVisibleAvatars(Math.min(maxAvatars, max));
  };

  useEffect(() => {
    const container = containerRef.current;

    if (!container) return;

    const resizeObserver = new ResizeObserver(() => {
      updateVisibleAvatars();
    });

    resizeObserver.observe(container);

    return () => {
      if (container) {
        resizeObserver.unobserve(container);
      }
    };
  }, []);

  const clampedMax = Math.max(visibleAvatars, 2);
  const extraAvatars = Math.max(users.length - clampedMax, 0);
  const extraAvatarNames = users
    .slice(clampedMax)
    .map((user) =>
      user.name ? user.name : `${user.firstName} ${user.lastName}`
    );

  const marginLeft = SPACINGS[spacing];

  return (
    <Box display="flex" flexDirection="row" ref={containerRef} width="100%">
      {userPhotos.slice(0, clampedMax).map(({ user, photo }, index) => {
        const userName = user.name
          ? user.name
          : `${user.firstName} ${user.lastName}`;
        const initials = userName
          .split(' ')
          .slice(0, 2)
          .map((word: string) => word[0]?.toUpperCase())
          .join('');

        return (
          <Tooltip arrow key={user.id} title={userName}>
            <Avatar
              alt={userName}
              src={photo || undefined}
              sx={{
                backgroundColor: resourseColors[index],
                cursor: 'pointer',
                height: sizeAvatar,
                marginLeft: index === 0 ? undefined : marginLeft,
                width: sizeAvatar,
                zIndex: users.length - index
              }}
            >
              {!photo && initials}
            </Avatar>
          </Tooltip>
        );
      })}
      {extraAvatars > 0 && (
        <CustomTooltip
          backgroundColor={theme.palette.grey[700]}
          borderColor={theme.palette.grey[700]}
          component={<ListExtraAvatars names={extraAvatarNames} />}
        >
          <Avatar
            style={{
              backgroundColor: resourseColors[clampedMax],
              height: sizeAvatar,
              marginLeft,
              width: sizeAvatar,
              zIndex: 0
            }}
          >
            +{extraAvatars}
          </Avatar>
        </CustomTooltip>
      )}
    </Box>
  );
};

export default AvatarGroup;
