import {
  AUTHORIZED_VIEW_NAMES,
  RESOURCES_TABS,
  CATALOG_TABS,
  CLIENT_TABS,
  DEFAULT_SCREENS,
  COMPANIES_TABS,
  LEAD_TABS,
  CLIENT_SCREENS
} from './routesConstants';

export const DASHBOARD_TAB = {
  icon: 'dashboard',
  position: 1,
  route: AUTHORIZED_VIEW_NAMES.DASHBOARD,
  text: 'Dashboard'
};

export const CALENDAR_TAB = {
  icon: 'calendar',
  position: 2,
  route: AUTHORIZED_VIEW_NAMES.CALENDAR,
  text: 'Calendar'
};

export const PROJECT_TAB = {
  icon: 'project',
  position: 3,
  route: DEFAULT_SCREENS.PROJECTS,
  text: 'Project'
};

export const ASSIGNMENTS_TAB = {
  icon: 'resources',
  position: 4,
  route: AUTHORIZED_VIEW_NAMES.ASSIGNMENTS,
  text: 'Assignments'
};

export const CLIENT_TAB = {
  children: [
    {
      route: CLIENT_TABS.NEW_CLIENT,
      text: 'Create'
    },
    {
      route: CLIENT_TABS.CLIENTS,
      text: 'Clients'
    },
    {
      route: CLIENT_TABS.IMPORT_CLIENTS,
      text: 'Import'
    }
  ],
  icon: 'client',
  position: 5,
  route: '',
  text: 'Client'
};

export const LEAD_TAB = {
  children: [
    {
      route: LEAD_TABS.NEW,
      text: 'Create'
    },
    {
      route: LEAD_TABS.LEADS,
      text: 'Leads'
    }
  ],
  icon: 'lead',
  position: 6,
  route: '',
  text: 'Lead'
};

export const CHAT_TAB = {
  icon: 'chat',
  position: 7,
  route: DEFAULT_SCREENS.CHATS,
  text: 'Chat'
};

export const TIMESHEET = {
  icon: 'timesheet',
  position: 8,
  route: AUTHORIZED_VIEW_NAMES.TIMESHEET,
  text: 'Timesheet'
};

export const DOCUMENTS_TAB = {
  icon: 'documents',
  position: 9,
  route: '',
  text: 'Documents'
};

export const CATALOG_TAB = {
  children: [
    {
      route: CATALOG_TABS.SERVICE,
      text: 'Services'
    },
    {
      route: CATALOG_TABS.TASK,
      text: 'Tasks'
    },
    {
      route: CATALOG_TABS.SKILLS,
      text: 'Skills'
    },
    {
      route: CATALOG_TABS.CATEGORIES,
      text: 'Categories'
    },
    {
      route: CATALOG_TABS.MATERIALS,
      text: 'Materials'
    },
    {
      route: CATALOG_TABS.PROPOSAL_MESSAGES,
      text: 'Proposal Messages'
    }
  ],
  icon: 'catalog',
  position: 10,
  route: '',
  text: 'Catalog'
};

export const CATALOG_SUPERUSER_TAB = {
  children: [
    {
      route: CATALOG_TABS.SERVICE,
      text: 'Services'
    },
    {
      route: CATALOG_TABS.TASK,
      text: 'Tasks'
    },
    {
      route: CATALOG_TABS.SKILLS,
      text: 'Skills'
    },
    {
      route: CATALOG_TABS.CATEGORIES,
      text: 'Categories'
    },
    {
      route: CATALOG_TABS.MATERIALS,
      text: 'Materials'
    },
    {
      route: CATALOG_TABS.PROPOSAL_MESSAGES,
      text: 'Proposal Messages'
    },
    {
      route: CATALOG_TABS.WIDGETS,
      text: 'Widgets'
    }
  ],
  icon: 'catalog',
  position: 10,
  route: '',
  text: 'Catalog'
};

export const RESOURCES_TAB = {
  children: [
    {
      route: RESOURCES_TABS.INVITATIONS,
      text: 'Invitations'
    },
    {
      route: RESOURCES_TABS.USERS,
      text: 'Users'
    }
  ],
  icon: 'resources',
  position: 11,
  route: '',
  text: 'Resources'
};

export const RESOURCES_OPT_TAB = {
  children: [
    {
      route: RESOURCES_TABS.INVITATIONS,
      text: 'Invitations'
    },
    {
      route: RESOURCES_TABS.USERS,
      text: 'Users'
    }
  ],
  icon: 'resources',
  position: 11,
  route: '',
  text: 'Resources'
};

export const RESOURCES_ADMIN_TAB = {
  children: [
    {
      route: RESOURCES_TABS.INVITATIONS,
      text: 'Invitations'
    },
    {
      route: RESOURCES_TABS.USERS,
      text: 'Users'
    },
    {
      route: RESOURCES_TABS.REQUESTS,
      text: 'Requests'
    }
  ],
  icon: 'resources',
  position: 11,
  route: '',
  text: 'Resources'
};

export const COMPANIES_TAB = {
  children: [
    {
      route: COMPANIES_TABS.INVITATIONS,
      text: 'Invitations'
    },
    {
      route: COMPANIES_TABS.COMPANIES,
      text: 'Companies'
    }
  ],
  icon: 'resources',
  position: 12,
  route: '',
  text: 'Companies'
};

export const COMPANY_SETTINGS = {
  icon: 'settings',
  position: 13,
  route: AUTHORIZED_VIEW_NAMES.COMPANY_SETTINGS,
  text: 'Settings'
};

export const CLIENT_DASHBOARD_TAB = {
  icon: 'dashboard',
  position: 1,
  route: CLIENT_SCREENS.DASHBOARD,
  text: 'Dashboard'
};
export const CLIENT_PROJECT_TAB = {
  icon: 'project',
  position: 2,
  route: CLIENT_SCREENS.PROJECTS,
  text: 'Projects'
};

export const CLIENT_INVOICES_TAB = {
  icon: 'calculator',
  position: 3,
  route: CLIENT_SCREENS.INVOICES,
  text: 'Invoices'
};

export const CLIENT_INFORMATION_TAB = {
  icon: 'resources',
  position: 4,
  route: CLIENT_SCREENS.INFORMATION,
  text: 'Information'
};

export const CLIENT_PROPOSAL_TAB = {
  icon: 'project',
  position: 5,
  route: CLIENT_SCREENS.PROPOSALS,
  text: 'Proposals'
};

export default {
  // ASSIGNMENTS_TAB,
  CALENDAR_TAB,
  CATALOG_SUPERUSER_TAB,
  CATALOG_TAB,
  CHAT_TAB,
  CLIENT_TAB,
  COMPANIES_TAB,
  COMPANY_SETTINGS,
  DOCUMENTS_TAB,
  LEAD_TAB,
  PROJECT_TAB,
  RESOURCES_ADMIN_TAB,
  RESOURCES_OPT_TAB,
  RESOURCES_TAB,
  TIMESHEET
};
