/* eslint-disable max-len */
import {
  ProfileApi,
  AuthenticationApi,
  InvitationApi,
  MFAApi,
  CompanyInvitationApi,
  UserManagementApi,
  ClientsAuthApi
} from '../api-client/generated/api';
import { setLocalStorage } from '../utils/authHelper';

interface userAuth {
  companyDescription?: string;
  email: string;
  password: string;
  rememberMe: boolean;
  token: string;
}

interface userUpdate {
  email?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  photo?: string;
}

interface userRequestAvailable {
  requestedAvailableDays: [string];
  requestedTimeEnd: string;
  requestedTimeStart: string;
}

const getUsers = async () => {
  const Api = new UserManagementApi();
  const response = await Api.usersFindAll();
  const { data } = response;
  return data as unknown as Array<any>;
};

const getProfile = async (params: userAuth) => {
  if (!params) {
    return null;
  }
  const profileApi = new ProfileApi();
  const response = await profileApi.profileGetProfile();
  const { data }: any = response;
  return data;
};

const getProfileById = async (id: string) => {
  const profileApi = new ProfileApi();
  const response = await profileApi.profileGetProfileById({ id });
  return response.data as any;
};

const setProfileById = async (params: any) => {
  const { id, profile } = params;
  const {
    rate,
    jobTitle,
    availableDays,
    availableTimeStart,
    availableTimeEnd
  } = profile;
  const profileApi = new ProfileApi();
  const response = await profileApi.profileUpdateUserProfile({
    id,
    updateUserProfileDto: {
      avaialableTimeEnd: availableTimeEnd,
      availableDays,
      availableTimeStart,
      jobTitle,
      rate: parseFloat(rate),
      skills: profile.skills.map((item: any) => item.id),
      userRoles: profile?.roles.map((item: any) => item.id)
    }
  });
  return response.data as any;
};

const setUpdateProfile = async (params: userUpdate) => {
  if (!params) {
    return null;
  }
  const { email, firstName, lastName, phoneNumber, photo } = params;
  const profileApi = new ProfileApi();
  const response = await profileApi.profileUpdateProfile({
    updateCurrentProfileDto: { email, firstName, lastName, phoneNumber, photo }
  });
  const { data }: any = response;
  return data;
};

const setRequestChangeTime = async (params: userRequestAvailable) => {
  if (!params) {
    return null;
  }
  const { requestedAvailableDays, requestedTimeEnd, requestedTimeStart } =
    params;
  const profileApi = new ProfileApi();
  const response = await profileApi.profileChangeCurrentUserAvilableTimeRequest(
    {
      availableTimeRequestedDTO: {
        requestedAvailableDays,
        requestedTimeEnd,
        requestedTimeStart
      }
    }
  );
  const { data }: any = response;
  return data;
};

const setCancelRequestChangeTime = async (requestId: string) => {
  if (!requestId) {
    return null;
  }
  const profileApi = new ProfileApi();
  const response = await profileApi.profileCancelRequest({ requestId });
  const { data }: any = response;
  return data;
};

const getRequestChangeTime = async (params: userAuth) => {
  if (!params) {
    return null;
  }
  const profileApi = new ProfileApi();
  const response =
    await profileApi.profileGetAllCurrentUserAvailableTimeRequest();
  const { data }: any = response;
  return data;
};

const tokenValid = async () => {
  const authApi = new AuthenticationApi();
  const response = await authApi.authVerifyToken();
  const { data } = response;
  setLocalStorage('accessToken', data.accessToken);
  return data;
};

const setAcceptInvitation = async (params: userAuth) => {
  if (!params) {
    return null;
  }
  const { token, password } = params;
  const invitationApi = new InvitationApi();
  const response = await invitationApi.invitationAcceptsInvitation({
    acceptInvitationBodyDTO: { password },
    token
  });
  const { data } = response;
  setLocalStorage('accessToken', data.accessToken);
  setLocalStorage('onboarding', true);
  return data;
};

const validInvitation = async (token: string) => {
  if (!token) {
    return null;
  }
  const invitationApi = new InvitationApi();
  const response = await invitationApi.invitationIsValidInvitation({ token });
  const { status, statusText } = response;
  return { status, statusText };
};

const setAcceptCompanyInvitation = async (params: userAuth) => {
  if (!params) {
    return null;
  }
  const { token, password, companyDescription } = params;
  const invitationApi = new CompanyInvitationApi();
  const response = await invitationApi.companyInvitationAcceptInvitation({
    acceptCompanyInvitationDTO: { companyDescription, password },
    token
  });
  const { data } = response;
  setLocalStorage('accessToken', data.accessToken);
  setLocalStorage('onboarding', true);
  return data;
};

const validCompanyInvitation = async (token: string) => {
  if (!token) {
    return null;
  }
  const invitationApi = new CompanyInvitationApi();
  const response = await invitationApi.companyInvitationIsValidInvitation({
    token
  });
  const { status, statusText } = response;
  return { status, statusText };
};

const getAuthenticationUser = async (params: userAuth) => {
  if (!params) {
    return null;
  }
  const { email, password, rememberMe } = params;
  const authApi = new AuthenticationApi();
  const response = await authApi.authLogin({
    loginDto: { email, password, rememberMe }
  });
  const { data } = response;
  if (data.accessToken) {
    setLocalStorage('accessToken', data.accessToken);
  }
  return data;
};

const logout = async () => {
  // if is necessary to logout in the BE
  // const response = await apiCaller.authLogin({ loginDto: { email, password } });
  // config = new Configuration();
  return true;
};

const confirmCode = async (param: string) => {
  // const response = await apiCaller.authLogin({ loginDto: { email, password } });
  const mfaApi = new MFAApi();
  const response = await mfaApi
    .twoFactorConfirmTwoFactor({ twoFactorConfirmBodyDTO: { code: param } })
    .catch((error) => ({
      status: error.response.status,
      statusText: error.response.data.message
    }));
  const { status, statusText } = response;
  return { status, statusText };
};

const sendCodeMFA = async () => {
  // const response = await apiCaller.authLogin({ loginDto: { email, password } });
  const mfaApi = new MFAApi();
  const response = await mfaApi.twoFactorSendCode();
  const { status, statusText } = response;
  return { status, statusText };
};

const authMFA = async (params: any) => {
  if (!params.code) {
    return null;
  }
  const mfaApi = new AuthenticationApi();
  const response = await mfaApi.authLoginTwoFactor({
    twoFactorBodyDTO: { ...params }
  });
  const { data }: any = response;
  if (data.accessToken) {
    setLocalStorage('accessToken', data.accessToken);
  }
  return data;
};

const forgotPassword = async (params: any) => {
  const { email } = params;
  const authApi = new AuthenticationApi();
  const response = await authApi.authCreate({
    forgotPasswordCreateDTO: { email }
  });
  const { status, statusText } = response;
  return { status, statusText };
};

const changePassword = async (params: any) => {
  const { email, oldPassword, newPassword } = params;
  const authApi = new AuthenticationApi();
  const response = await authApi.authChangePassword({
    authChangePasswordBodyDTO: { email, newPassword, oldPassword }
  });
  const { status, statusText } = response;
  return { status, statusText };
};

const resetPassword = async (params: any) => {
  const { password, token } = params;
  const authApi = new AuthenticationApi();
  const response = await authApi.authResetPassword({
    resetPasswordTokenDTO: { password },
    token
  });
  const { status, statusText } = response;
  return { status, statusText };
};

const sendInfoToMFA = async (params: any) => {
  // const response = await apiCaller.authLogin({ loginDto: { email, password } });
  const { methodSelected, dataToSend } = params;
  const mfaApi = new MFAApi();
  const response = await mfaApi.twoFactorAssignTwoFactor({
    twoFactorCreateBodyDTO:
      methodSelected === 1
        ? { method: 'Phone', phoneNumber: dataToSend.phoneNumber }
        : { method: 'Email' }
  });
  const { status, statusText } = response;
  return { status, statusText };
};

const sendUpdateInfoToMFA = async (params: any) => {
  const { methodSelected, dataToSend } = params;
  const mfaApi = new MFAApi();
  const response = await mfaApi.twoFactorUpdateTwoFactor({
    twoFactorUpdateBodyDTO:
      methodSelected === 1
        ? { method: 'Phone', phoneNumber: dataToSend.phoneNumber }
        : { email: dataToSend.email, method: 'Email' }
  });
  const { status, statusText } = response;
  return { status, statusText };
};

const authArchiveUser = async (params: any) => {
  const { id } = params;
  const apiCaller = new AuthenticationApi();
  const response: any = await apiCaller.authArchiveUser({ id });
  return { data: response.data, status: response.status };
};

const authUnarchiveUser = async (params: any) => {
  const { id } = params;
  const apiCaller = new AuthenticationApi();
  const response: any = await apiCaller.authUnarchiveUser({ id });
  return { ...response.data, status: response.status };
};

const getProfilePaginated = async (params: any) => {
  const { items, page } = params;
  const apiCaller = new ProfileApi();
  const response: any = await apiCaller.profileGetAllProfilesPaginated({
    items,
    page
  });
  return response.data;
};

const unblockUser = async (params: any) => {
  const { email } = params;
  const apiCaller = new AuthenticationApi();
  const response: any = await apiCaller.authUnblockUser({
    unblockUserDTO: { email }
  });
  return { ...response.data, status: response.status };
};

const updateTimezoneForCurrentUser = async (params: any) => {
  const apiCaller = new ProfileApi();
  const response: any = await apiCaller.profileUpdateTimezoneForCurrentUser({
    userTimezoneDTO: {
      timezone: new Date().getTimezoneOffset().toString(),
      timezoneIana: Intl.DateTimeFormat().resolvedOptions().timeZone
    }
  });
  return { ...response.data, status: response.status };
};

const updateTimezoneIanaForCurrentUser = async () => {
  const apiCaller = new ProfileApi();
  const response: any = await apiCaller.profileUpdateTimezoneIanaForCurrentUser(
    {
      userTimezoneIanaDTO: {
        timezoneIana: Intl.DateTimeFormat().resolvedOptions().timeZone
      }
    }
  );
  return { ...response.data, status: response.status };
};

export default {
  authArchiveUser,
  authMFA,
  authUnarchiveUser,
  changePassword,
  confirmCode,
  forgotPassword,
  getAuthenticationUser,
  getProfile,
  getProfileById,
  getProfilePaginated,
  getRequestChangeTime,
  getUsers,
  logout,
  resetPassword,
  sendCodeMFA,
  sendInfoToMFA,
  sendUpdateInfoToMFA,
  setAcceptCompanyInvitation,
  setAcceptInvitation,
  setCancelRequestChangeTime,
  setProfileById,
  setRequestChangeTime,
  setUpdateProfile,
  tokenValid,
  unblockUser,
  updateTimezoneForCurrentUser,
  updateTimezoneIanaForCurrentUser,
  validCompanyInvitation,
  validInvitation
};
